import type { I18nDefinition } from '@backmarket/nuxt-module-i18n/types'

const translations: Record<string, I18nDefinition> = {
  title: {
    id: 'insurance_billing_address_page',
    defaultMessage: "What's your billing address?",
  },
}

export default translations
