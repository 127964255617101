import type { I18nDefinition } from '@backmarket/nuxt-module-i18n/types'

const translations: Record<string, I18nDefinition> = {
  purchaseDate: {
    id: 'insurance_payment_page_purchase',
    defaultMessage: 'Purchased on the {date}',
  },
}

export default translations
