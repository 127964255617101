import type { I18nDefinition } from '@backmarket/nuxt-module-i18n/types'

const translations: Record<string, I18nDefinition> = {
  billTo: {
    id: 'insurance_address_summary_bill_to',
    defaultMessage: 'Bill to {fullName}',
  },
  edit: {
    id: 'insurance_address_summary_edit',
    defaultMessage: 'Edit',
  },
}

export default translations
