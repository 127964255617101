<template>
  <div>
    <h3 class="heading-3 mb-24 md:mb-16">
      {{ i18n(translations.title) }}
    </h3>

    <CheckoutBillingAddressForm
      :address="billingModel"
      :api-errors
      :submitting="isSubmitting"
      @submit="handleSubmit"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import { HttpApiError } from '@backmarket/http-api'
import { postAddress } from '@backmarket/http-api/src/api-specs-after-sale-experience/client/shipping-address'
import type { Bill } from '@backmarket/http-api/src/api-specs-checkout/cart/cart.types'
import type { InsuranceBilling } from '@backmarket/http-api/src/api-specs-checkout/checkout/ShoppingSession.types'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTheToast } from '@backmarket/nuxt-module-toast/useTheToast'
import { camelizeKeys } from '@backmarket/utils/object/camelizeKeys'

import type { FormValues } from '~/scopes/checkout/pages/BillingAddress/components/BillingAddressForm.types'
import CheckoutBillingAddressForm from '~/scopes/checkout/pages/BillingAddress/components/BillingAddressForm.vue'

import translations from './BillingAddress.translations'

const props = defineProps<{
  address: InsuranceBilling
}>()
const emit = defineEmits(['post-address-success'])

const i18n = useI18n()

const { openErrorToast } = useTheToast()

const apiErrors = ref<{ [key: string]: string }>({})
const isSubmitting = ref(false)

const billingModel = computed<Bill>(() => {
  return {
    firstName: props.address.firstName,
    firstNamePronunciation: props.address.firstNamePronunciation ?? null,
    lastName: props.address.lastName,
    lastNamePronunciation: props.address.lastNamePronunciation ?? null,
    phone: props.address.phoneNumber,
    postalCode: props.address.postalCode,
    stateOrProvince: props.address.stateOrProvince,
    street: props.address.addressLine1,
    street2: props.address.addressLine2,
    city: props.address.city,
    company: props.address.company,
    country: props.address.country,
    birthdate: null,
    countryDialInCode: null,
    customerIdNumber: null,
    dateCreation: null,
    dateModification: null,
    email: null,
  }
})

const getApiErrors = (error: Record<string, unknown>) => {
  const camelizedError = camelizeKeys(error)
  // FIXME Needs to update the httpApiError to manage endpointSettings.transformResponseToCamelCase

  return Object.keys(camelizedError).reduce(
    (acc, key) => ({
      ...acc,
      [key]:
        Array.isArray(camelizedError[key]) &&
        (camelizedError[key] as Array<string>).length > 0
          ? (camelizedError[key] as Array<string>)[0]
          : '',
    }),
    {},
  )
}

const handleSubmit = async (values: Omit<FormValues, 'nationalId'>) => {
  isSubmitting.value = true
  apiErrors.value = {}

  try {
    await $httpFetch(postAddress, {
      body: { isShipping: false, isBilling: true, ...values },
    })

    emit('post-address-success')
  } catch (error) {
    const httpApiError = error as HttpApiError

    if (httpApiError.status === 400) {
      Object.assign(apiErrors.value, getApiErrors({ ...httpApiError }))
    } else {
      openErrorToast()
    }
  } finally {
    isSubmitting.value = false
  }
}
</script>
