import type { I18nDefinition } from '@backmarket/nuxt-module-i18n/types'

const translations: Record<string, I18nDefinition> = {
  title: {
    id: 'insurance_payment_page_user_information_title',
    defaultMessage: 'Personal information',
  },
}

export default translations
