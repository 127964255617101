import type { I18nDefinition } from '@backmarket/nuxt-module-i18n/types'

const translations: Record<string, I18nDefinition> = {
  coverageBegins: {
    id: 'insurance_summary_coverage_begins',
    defaultMessage: 'Coverage begins',
  },
  expires: {
    id: 'insurance_summary_expires',
    defaultMessage: 'Expires on',
  },
  total: {
    id: 'insurance_summary_total',
    defaultMessage: 'Total',
  },
  forProduct: {
    id: 'insurance_summary_for_product',
    defaultMessage: 'For {model}',
  },
  subtotal: {
    id: 'insurance_summary_subtotal',
    defaultMessage: 'Subtotal',
  },
  tax: {
    id: 'insurance_summary_tax',
    defaultMessage: 'Tax',
  },
}

export default translations
