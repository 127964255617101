<template>
  <RevCard class="flex flex-col gap-24 p-32">
    <div class="flex">
      <RevIllustration
        alt=""
        class="mr-20"
        :height="56"
        :src="orderline.image"
        :width="56"
      />
      <div class="flex flex-col">
        <h3 class="body-1-bold">{{ orderline.title }}</h3>
        <span class="body-2 mt-1 md:mt-2">
          {{
            i18n(translations.purchaseDate, {
              date: i18n.date(new Date(orderline.purchaseDate)),
            })
          }}
        </span>
      </div>
    </div>

    <div class="space-y-24">
      <div class="space-y-12">
        <InsuranceOffers
          :id="orderline.listingId"
          :offers
          :selected-offer-id="selectedOffer.id"
          :tracking-zone
          @select="handleSelect"
        />

        <InsuranceProvider :tracking-zone with-link />
      </div>

      <InsuranceCompliancy
        :id="`compliancy-${orderline.listingId}`"
        :offer="selectedOffer"
        @update="handleUpdateCompliancy"
      />
    </div>
  </RevCard>
</template>

<script setup lang="ts">
import { ref } from 'vue'

import type {
  InsuranceOffer,
  InsuranceOfferWithSelectedState,
} from '@backmarket/http-api'
import type { InsuranceOrderline } from '@backmarket/http-api/src/api-specs-checkout/checkout/ShoppingSession.types'
import {
  postShoppingSessionAcceptAgreement,
  postShoppingSessionSelectInsurance,
} from '@backmarket/http-api/src/api-specs-checkout/checkout/checkout'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevCard } from '@ds/components/Card'
import { RevIllustration } from '@ds/components/Illustration'

import InsuranceCompliancy from '~/scopes/insurance/components/InsuranceCompliancy/InsuranceCompliancy.vue'
import InsuranceOffers from '~/scopes/insurance/components/InsuranceOffers/InsuranceOffers.vue'
import InsuranceProvider from '~/scopes/insurance/components/InsuranceOffers/components/InsuranceProvider/InsuranceProvider.vue'
import { getOfferWithoutSelectedState } from '~/scopes/insurance/utils/insuranceOffers'

import translations from './InsuranceOfferCard.translations'

const props = defineProps<{
  shoppingSessionId: string
  itemId: string
  orderline: InsuranceOrderline
  offers: InsuranceOfferWithSelectedState[]
}>()

const emit = defineEmits(['insurance-option-updated'])

const selectedOffer = ref(
  getOfferWithoutSelectedState(
    props.offers.find((offer) => offer.selected) ||
      props.offers.find((offer) => offer.defaultOffer) ||
      props.offers[0],
  ),
)

const i18n = useI18n()

const trackingZone = 'insurance_post_purchase'

async function handleSelect(insuranceOffer: InsuranceOffer) {
  selectedOffer.value = insuranceOffer

  await $httpFetch(postShoppingSessionSelectInsurance, {
    pathParams: { shoppingSessionId: props.shoppingSessionId },
    body: {
      itemId: props.itemId,
      insuranceOfferId: insuranceOffer.id,
    },
  })

  emit('insurance-option-updated')
}

const handleUpdateCompliancy = async (updatedOffer: InsuranceOffer) => {
  selectedOffer.value = updatedOffer

  await $httpFetch(postShoppingSessionAcceptAgreement, {
    pathParams: { shoppingSessionId: props.shoppingSessionId },
    body: {
      itemId: props.itemId,
      accepted: updatedOffer.compliancy.isUserAgreementAccepted,
    },
  })

  emit('insurance-option-updated')
}
</script>
