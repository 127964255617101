import type { I18nDefinition } from '@backmarket/nuxt-module-i18n/types'

const translations: Record<string, I18nDefinition> = {
  title: {
    id: 'insurance_payment_page_title_logo',
    defaultMessage: 'Your {logo, html} cover',
  },
}

export default translations
