import type { I18nDefinition } from '@backmarket/nuxt-module-i18n/types'

const translations: Record<string, I18nDefinition> = {
  pageTitle: {
    id: 'insurance_page_title',
    defaultMessage: 'Renew your insurance | Back Market',
  },
  description: {
    id: 'insurance_page_description',
    defaultMessage:
      'Renew your insurance and make sure your product is covered.',
  },
  summaryTitle: {
    id: 'insurance_summary_title',
    defaultMessage: 'Summary',
  },
}

export default translations
