<template>
  <h3 class="heading-3">
    {{ i18n(translations.title) }}
  </h3>
  <RevCard class="p-32">
    <UserInformationFields
      v-model:birthdate="birthdate"
      :errors="{ birthdate: birthdateError }"
      :with-nationality="false"
    />
  </RevCard>
</template>

<script setup lang="ts">
import { watch } from 'vue'

import { InputAddressBirthdateValidators } from '@backmarket/nuxt-module-address/InputAddressBirthdateValidators'
import UserInformationFields from '@backmarket/nuxt-module-address/UserInformationFields.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevCard } from '@ds/components/Card'
import { FORM_VALID } from '@ds/components/Form'

import translations from './UserInformation.translations'

const birthdate = defineModel<string>('birthdate')
const birthdateError = defineModel<string | null>('birthdateError', {
  default: null,
})

const i18n = useI18n()

watch(birthdate, (birthdateValue) => {
  const error =
    birthdateValue &&
    // The Validator type from the DS provides the value, the values, and the Validation Context which we do not need
    InputAddressBirthdateValidators(i18n).isLegalAge(
      new Date(birthdateValue),
      {},
      {},
    )

  // The validator returns 1 if the birthdate is valid
  if (error && error !== FORM_VALID) {
    birthdateError.value = error
  } else {
    birthdateError.value = null
  }
})
</script>
